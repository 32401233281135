import React from 'react';
import { Table, Button } from 'react-bootstrap';
import '../App.css';

const Affiliates = () => {
  const data = [
    { company: "Apex Trader Funding", discount: "90%", resetDiscount: "0%", accFee: "0%", signUpLink: "https://bit.ly/ATFPICKLE", coupon: "PICKLE", dealEnd: "01/02/25", extra: "Get 90% OFF the 1st Month on ANY Size Evaluation! <br/>Plus, enjoy 80% OFF recurring months! <br/>RESETS ONLY 35$ <br/>GET 150k,250k,350k EVALS FOR 16$ (SAME PRICE AS A 50k)" },
    { company: "Elite Trader Funding", discount: "85%", resetDiscount: "0%", accFee: "0%", signUpLink: "https://tinyurl.com/Picklesir", coupon: "👈 Click the link", dealEnd: "On-going", extra: "The only futures account type that allows you to SWING TRADE FUTURES! 85% OFF of Non-Fast Track and 65% OFF of Fast Track accounts!" },
    { company: "TradeDay", discount: "20%", resetDiscount: "0%", accFee: "0%", signUpLink: "https://members.tradeday.com/?aff=TD_SirPickle", coupon: "PICKLE", dealEnd: "On-going", extra: "Discount applied when you click the link!" },
    { company: "MyFundedFutures", discount: "40%", resetDiscount: "0%", accFee: "0%", signUpLink: "https://myfundedfutures.com/?ref=965", coupon: "PICKLE", dealEnd: "01/31/25", extra: "40% OFF first time users <br/>20% OFF existing users" },
    { company: "Tradezella", discount: "0%", resetDiscount: "0%", accFee: "0%", signUpLink: "https://bit.ly/tradezellapickle", coupon: "PICKLE", dealEnd: "On-going", extra: "The best journalling software on the market!" },
  ];

  const buttonStyle = {
    backgroundColor: '#8CB65A',
    borderColor: '#8CB65A',
    color: 'white'
  };

  const buttonHoverStyle = {
    backgroundColor: 'black',
    borderColor: 'black',
    color: 'white'
  };

  return (
    <div>
      <div className="affiliates-table-wrapper">
      <h1><b>Prop Deals at a Glance</b></h1>
      <p>Ready to fund your profitable strategy?</p>
        <Table striped bordered hover className="affiliates-table-two">
          <thead>
            <tr>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Company</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Discount</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Reset Discount</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Activation Fee Discount</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Sign Up Link</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Coupon Code</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Deal Ends</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Extra</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.company}</td>
                <td>{item.discount}</td>
                <td>{item.resetDiscount}</td>
                <td>{item.accFee}</td>
                <td><Button 
                    href={item.signUpLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={buttonStyle}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor;
                      e.currentTarget.style.borderColor = buttonHoverStyle.borderColor;
                      e.currentTarget.style.color = buttonHoverStyle.color;
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor;
                      e.currentTarget.style.borderColor = buttonStyle.borderColor;
                      e.currentTarget.style.color = buttonStyle.color;
                    }}
                  >
                    Sign Up
                  </Button></td>
                <td dangerouslySetInnerHTML={{ __html: item.coupon }}></td>
                <td>{item.dealEnd}</td>
                <td dangerouslySetInnerHTML={{ __html: item.extra }}></td>
              </tr>
            ))}
          </tbody>
        </Table>
        <br></br>
      </div>
    </div>
  );
};

export default Affiliates;
