import React from "react";
import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/SirPickle.jpg';
import navIcon1 from '../assets/img/icons8-twitter-500.svg';
import navIcon2 from '../assets/img/icons8-youtube.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import './styles/NavBar.css';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" style={{borderRadius: "50%"}}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link href="#help" className={activeLink === 'help' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('help')}>Deals</Nav.Link>
              <Nav.Link href="#resources" className={activeLink === 'resources' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('resources')}>Resources</Nav.Link>
              <Nav.Link href="#propcomparisons" className={activeLink === 'propcomparisons' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('propcomparisons')}>Prop Firm Comparison Tool</Nav.Link>
              <Nav.Link href="#testimonials" className={activeLink === 'testimonials' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('testimonials')}>Testimonials</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://x.com/SirPickle_"><img src={navIcon1} alt="" /></a>
                <a href="https://www.youtube.com/@SirPickle_"><img src={navIcon2} alt="" /></a>
                <a href="https://www.instagram.com/sirpickle__/"><img src={navIcon3} alt="" /></a>
              </div>
              <a href="https://whop.com/the-jar-free-discord/" target="_blank" rel="noopener noreferrer">
                <button className="vvd"><span>Join The Pickle Jar</span></button>
              </a>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
